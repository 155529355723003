<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('General') }}
          </span>
      </template>

      <preset-map-save v-if="isLoad" />
    </b-tab>
    <!--/ general tab -->

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Detail') }}
          </span>
      </template>

      <preset-map-desc v-if="isLoad" />
    </b-tab>
    <!--/ general tab -->

  </b-tabs>
</template>

<script>
import { getDataVersion } from '@/store/ancestry-data/utils'
// Alert
import AlertService from '@/common/alert.service'

import PresetMapSave from './PresetMapSave.vue'
import PresetMapDesc from './PresetMapDesc.vue'

export default {
  components: {
    PresetMapSave,
    PresetMapDesc,
  },
  data() {
    return {
      isLoad: false,
    }
  },
  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('presetMapManagement/fetchPresetMap', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.isLoad = true;
              this.$store.commit('presetMapManagement/setMapField', response.result);
            } else {
              AlertService.error(this, response.message)
            }
          })
      } else {
        this.isLoad = true;
        this.$store.state.presetMapManagement.presetMapForm.dataVersion = getDataVersion(this.$store)
      }
    },
  },
  created() {
    this.$store.commit('presetMapManagement/resetMapField');
    this.fetchItem();
  },
}
</script>
